import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <div className=" p-3 bg-gray-100">
            <div className="flex flex-1 md:p-4 flex-col md:flex-row md:justify-center md:items-center">
                <div className="flex flex-1 justify-center items-center pt-10 md:pt-0">
                    <ul className="flex flex-1 flex-col justify-center items-center ">
                        <li className="pb-1 md:pr-10">
                            <a href="privacy.html" target="_blank">
                                Privacy Policy
                            </a>
                        </li>
                        <li className="pb-1 md:pr-10">
                            <a href="terms.html" target="_blank">
                                Terms of Service
                            </a>
                        </li>
                        <li className="pb-1 md:pr-10">
                            <a href="mailto:support@travzu.com">Contact us</a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-1 flex-col pt-10 pb-10 md:pt-0 md:pb-0 justify-center items-center">
                    <div>Follow us on</div>
                    <div className="flex">
                        <a href="https://www.instagram.com/travzu_official/" target="_blank">
                            <div className="socialMediaLogo p-4">
                                <i data-feather="instagram"></i>
                            </div>
                        </a>
                        <a href="https://twitter.com/travzu" target="_blank">
                            <div className="socialMediaLogo p-4">
                                <i data-feather="twitter"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="pb-4 text-center text-sm">Copyright ©2022 FLUXIT PRIVATE LIMITED</div>
        </div>
    );
};

export default Footer;

import React from "react";
import Typewriter from "typewriter-effect";

const features = ["Plan", "Manage", "Organize"];

const FeaturesTypewriter = () => {
    return (
        <Typewriter
            options={{
                strings: features,
                delay: 100,
                deleteSpeed: 50,
                autoStart: true,
                loop: true,
            }}
        />
    );
};

export default FeaturesTypewriter;

import React, { useState } from "react";
import AllReminders from "../assets/images/all-reminders-min.PNG";
import MyReminders from "../assets/images/my-reminders-min.PNG";
import "./Reminders.css";

const Reminders = () => {
    const [activeImage, setActiveImage] = useState("img2");
    return (
        <div className="feature flex flex-col md:flex-row justify-around justify-around	items-center feature mb-24">
            <div className="flex flex-1 justify-center items-center">
                <div className="flex flex-row justify-center items-center p-2">
                    <div
                        data-aos="fade-up"
                        className="cursor-pointer image_1_div h-3/5 w-3/5 md:h-2/5 md:w-72 -mt-16"
                        style={{
                            zIndex: activeImage === "img1" ? 1 : 0,
                            opacity: activeImage === "img1" ? 1 : 0.95,
                        }}
                        onClick={() => setActiveImage("img1")}
                    >
                        <img
                            src={MyReminders}
                            width="0px"
                            height="0px"
                            className="h-full w-full img lazy-load"
                            alt="My Reminders"
                        />
                    </div>
                    <div
                        data-aos="fade-down"
                        className="cursor-pointer image_2_div h-3/5 w-3/5 md:h-2/5 md:w-72"
                        style={{
                            zIndex: activeImage === "img2" ? 1 : 0,
                            opacity: activeImage === "img2" ? 1 : 0.95,
                        }}
                        onClick={() => setActiveImage("img2")}
                    >
                        <img
                            src={AllReminders}
                            idth="0px"
                            height="0px"
                            className="h-full w-full img lazy-load"
                            alt="All Reminders"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-1 pt-10 flex-col">
                <div className="max-w-md flex-col flex flex-1 p-4">
                    <div className="title font-bold text-4xl lg:text-5xl text-center lg:text-left">
                        Reminders
                    </div>
                    <span className="description text-lg md:text-xl text-center lg:text-left pt-6 lg:pt-12">
                        Assign reminders to yourself and others so that you{" "}
                        <span className="font-bold">don't miss out</span> anything!
                    </span>
                    <span className="description text-lg md:text-xl text-center lg:text-left pt-2">
                        Get suggestions as to what to carry for your kind of trip.
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Reminders;

import "./App.css";
import Budget from "./components/Budget";
import Expense from "./components/Expense";
import Footer from "./components/Footer";
import Intro from "./components/Intro";

import Navbar from "./components/Navbar";
import Plan from "./components/Plan";
import Reminders from "./components/Reminders";

function App() {
    return (
        <div>
            <title>Travzu</title>
            <Navbar />
            <Intro />
            <Expense />
            <Budget />
            <Reminders />
            <Plan />
            <Footer />
        </div>
    );
}

export default App;

import React from "react";
import AppFont from "../assets/images/app-font.png";
import "./Navbar.css";

const Navbar = () => {
    return (
        <div className="text-xl md:text-3xl p-3 md:p-4 navBarContainer">
            <img
                alt="Download on App Store"
                src={AppFont}
                width="0px"
                height="0px"
                className="h-6 w-32 md:h-8 md:w-40 lg:h-10 lg:w-48 lazy-load"
            />
        </div>
    );
};

export default Navbar;

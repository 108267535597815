import React, { useState } from "react";
import PlanMap from "../assets/images/mapview-map-min.PNG";
import PlanTimeline from "../assets/images/timeline-map-min.PNG";
import "./Plan.css";

const Plan = () => {
    const [activeImage, setActiveImage] = useState("img2");

    return (
        <div className="feature flex flex-col md:flex-row-reverse justify-around items-center feature mb-24">
            <div className="flex flex-1 justify-center items-center">
                <div className="flex flex-row justify-center items-center p-2">
                    <div
                        data-aos="fade-up-left"
                        className="cursor-pointer image_1_div h-3/5 w-3/5 md:h-2/5 md:w-72"
                        style={{
                            zIndex: activeImage === "img1" ? 1 : 0,
                            opacity: activeImage === "img1" ? 1 : 0.95,
                        }}
                        onClick={() => setActiveImage("img1")}
                    >
                        <img
                            src={PlanTimeline}
                            width="0px"
                            height="0px"
                            className="h-full w-full img lazy-load"
                            alt="Expenses Home"
                        />
                    </div>
                    <div
                        data-aos="fade-up-right"
                        className="cursor-pointer image_2_div h-3/5 w-3/5 md:h-2/5 md:w-72 -mt-16"
                        style={{
                            zIndex: activeImage === "img2" ? 1 : 0,
                            opacity: activeImage === "img2" ? 1 : 0.95,
                        }}
                        onClick={() => setActiveImage("img2")}
                    >
                        <img
                            src={PlanMap}
                            width="0px"
                            height="0px"
                            className="h-full w-full img img2 lazy-load"
                            alt="Category Expenses"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-1 pt-10 flex-col  items-end">
                <div className="flex-col flex flex-1 p-4 max-w-md">
                    <div className="title font-bold text-4xl lg:text-5xl text-center md:text-left">
                        Itinerary
                    </div>
                    <span className="description text-lg md:text-xl text-center md:text-left pt-6 lg:pt-12">
                        Plan your itinerary with a map view to{" "}
                        <span className="font-bold">avoid unnecessary travel</span>.
                    </span>
                    <span className="description text-lg md:text-xl text-center md:text-left pt-2">
                        No more "Hey where are we going?" over calls. View them all in one place.
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Plan;

import React from "react";
import DownloadFromAppStore from "../assets/images/download-on-app-store.svg";
import DownloadFromPlayStore from "../assets/images/download-on-play-store.svg";
import AppImage from "../assets/images/intro.svg";
import FeaturesTypewriter from "./FeaturesTypewriter";
import "./Intro.css";

const Intro = () => {
    return (
        <div className="flex flex-col-reverse md:flex-row justify-center items-center h-screen mb-20 md:mb-0 lg:-mt-12">
            <div className="flex flex-1 justify-start md:justify-end px-6 pt-16 md:pt-0 md:pt-24 lg:pt-32 md:pl-12">
                <div className="leftDiv">
                    <span className="heading1 font-semibold text-4xl lg:text-5xl xl:text-7xl">
                        <FeaturesTypewriter />
                    </span>
                    <span className="heading2 font-light text-3xl lg:text-4xl xl:text-6xl">
                        your travel
                    </span>
                    <br />
                    <span className="heading3 font-light text-3xl lg:text-4xl xl:text-6xl">
                        without any hassle
                    </span>
                    <p className="text-sm md:text-lg lg:text-xl leading-none font-light description pt-6 lg:pt-10 tracking-wider">
                        Macha, let's go somewhere?
                    </p>
                    <div className="images flex flex-row">
                        <a href="https://apps.apple.com/us/app/travzu/id1596457945" target="_blank">
                            <img
                                alt="Download on App Store"
                                src={DownloadFromAppStore}
                                width="0px"
                                height="0px"
                                className="h-32 w-32 md:h-32 md:w-32 lg:h-48 lg:w-48 lazy-load"
                            />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.travzu"
                            target="_blank"
                        >
                            <img
                                alt="Download on Play Store"
                                src={DownloadFromPlayStore}
                                width="0px"
                                height="0px"
                                className="h-32 w-32 md:h-32 md:w-32 lg:h-48 lg:w-48 ml-6 lazy-load"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex flex-1 justify-center items-center md:justify-center images ">
                <img
                    alt="App Cartoon"
                    src={AppImage}
                    width="0px"
                    height="0px"
                    className="h-5/6 w-5/6 lg:h-4/6 lg:w-4/6 lazy-load"
                />
            </div>
        </div>
    );
};

export default Intro;

import React from "react";
import BudgetImage from "../assets/images/budget-min.PNG";
import "./Budget.css";

const Budget = () => {
    return (
        <div className="feature flex flex-col md:flex-row-reverse justify-around items-center feature mb-24">
            <div className="flex flex-1 justify-center items-center">
                <div className="flex flex-row justify-center items-center p-2">
                    <div
                        data-aos="zoom-out"
                        className="h-3/5 w-3/5 md:h-2/5 md:w-72"
                        style={{ zIndex: 1 }}
                    >
                        <img
                            src={BudgetImage}
                            width="0px"
                            height="0px"
                            className="h-full w-full img lazy-load"
                            alt="Budget"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-1 pt-10 flex-col  items-end">
                <div className="flex-col flex flex-1 p-4 max-w-md">
                    <div className="title font-bold text-4xl lg:text-5xl text-center md:text-left">
                        Budget
                    </div>
                    <span className="description text-lg md:text-xl text-center md:text-left pt-6 lg:pt-12">
                        Allot budget to each kind of expense category.{" "}
                        <span className="font-bold">Analyze</span> where you have spent more.
                    </span>
                    <span className="description text-lg md:text-xl text-center md:text-left pt-2">
                        See if you have some bucks left to visit one more place!
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Budget;

import React, { useState } from "react";
import CategorySummary from "../assets/images/category-summary-min.PNG";
import ExpenseHome from "../assets/images/expenses-home-min.PNG";
import "./Expense.css";

const Expense = () => {
    const [activeImage, setActiveImage] = useState("img2");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onClickWatchVideo = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className="feature flex flex-col md:flex-row justify-around justify-around	items-center feature mb-24">
            <div className="flex flex-1 justify-center items-center">
                <div className="flex flex-row justify-center items-center p-2">
                    <div
                        data-aos="fade-left"
                        className="cursor-pointer image_1_div h-3/5 w-3/5 md:h-2/5 md:w-72"
                        style={{
                            zIndex: activeImage === "img1" ? 1 : 0,
                            opacity: activeImage === "img1" ? 1 : 0.95,
                        }}
                        onClick={() => setActiveImage("img1")}
                    >
                        <img
                            src={ExpenseHome}
                            width="0px"
                            height="0px"
                            className="h-full w-full img lazy-load"
                            alt="Expenses Home"
                        />
                    </div>
                    <div
                        data-aos="fade-right"
                        className="cursor-pointer image_2_div h-3/5 w-3/5 md:h-2/5 md:w-72 -mt-16"
                        style={{
                            zIndex: activeImage === "img2" ? 1 : 0,
                            opacity: activeImage === "img2" ? 1 : 0.95,
                        }}
                        onClick={() => setActiveImage("img2")}
                    >
                        <img
                            src={CategorySummary}
                            width="0px"
                            height="0px"
                            className="h-full w-full img img2 lazy-load"
                            alt="Category Expenses"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-1 pt-10 flex-col">
                <div className="max-w-md flex-col flex flex-1 p-4">
                    <div className="title font-bold text-4xl lg:text-5xl text-center md:text-left">
                        Expenses
                    </div>

                    <span className="description text-lg md:text-xl text-center md:text-left pt-6 md:pt-12">
                        Manage your expenses with custom currencies.{" "}
                        <span className="font-bold">Visualize</span> them with graphs and spend
                        accordingly.
                    </span>
                    <span className="description text-lg md:text-xl text-center md:text-left pt-2">
                        View settlement among your fellow travellers and{" "}
                        <span className="font-bold">simplify your debts</span>.
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Expense;
